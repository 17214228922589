<template>
  <b-tab title="Attribute 2">
    <b-row>
      <b-col md="8">
        <b-col md="12">
          <b-form class="repeater-form" @submit.prevent="repeatVariation">
            <div
              v-for="(item, index) in variations"
              :id="String(index)"
              :key="index"
              class="d-flex align-items-center mb-1"
            >
              <b-form-group
                :label="`Variation ${index + 1}:`"
                label-cols-md="3"
                class="w-100"
              >
                <div class="bg-gray w-100">
                  <b-form-group label="Name" label-cols-md="3">
                    <b-form-input
                      v-model="variations[index].name"
                      placeholder="Enter Variation Name, eg: Color"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Options"
                    label-cols-md="3"
                    v-if="
                      variations &&
                        variations[index] &&
                        variations[index]['options']
                    "
                  >
                    <b-form
                      class="repeater-form"
                      @submit.prevent="repeatOption(index)"
                    >
                      <div
                        v-for="(i, idx) in variations[index]['options']"
                        :id="String(idx)"
                        :key="idx"
                        class="d-flex align-items-center mb-1"
                      >
                        <b-form-input
                          v-model="variations[index]['options'][idx]"
                          class="mr-3"
                          placeholder="Enter Option for Variation Name, eg: Red"
                        />
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon rounded-circle"
                          @click="removeOption(index, idx)"
                        >
                          <feather-icon icon="Trash2Icon" class="mr-25" />
                        </b-button>
                      </div>
                    </b-form>
                  </b-form-group>

                  <b-form-group label-cols-md="3" label="">
                    <div class="fade-browse" @click="repeatOption(index)">
                      <feather-icon icon="PlusCircleIcon" class="mr-25" />
                      Add options ({{ variations[index]['options'].length }}/20)
                    </div>
                  </b-form-group>
                </div>
              </b-form-group>
            </div>
          </b-form>
        </b-col>
        <b-col md="12" v-if="variations.length < 20">
          <b-form-group label="* Variation:" label-cols-md="3">
            <div class="fade-browse" @click="repeatVariation">
              <feather-icon icon="PlusCircleIcon" class="mr-25" />
              Add variations ({{ variations.length }}/20)
            </div>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-button
            type="submit"
            variant="primary"
            block
            @click="handleCreateAttribute"
          >
            Create Attribute
          </b-button>
        </b-col>
      </b-col>
      <b-col md="4">
        <div class="bg-gray">
          <div><b>Variation and options you've typed for attribute 2:</b></div>
          <template
            v-if="
              variations && variations.length > 0 && variations[0].name !== null
            "
          >
            <div
              v-for="(item, index) in variations"
              :key="index"
              class="mt-1 d-block"
            >
              <b>{{ item.name }}:</b>

              <template
                v-if="
                  variations[index].options &&
                    variations[index].options.length > 0 &&
                    variations[index].options[0] !== ''
                "
              >
                <b-badge
                  v-for="(it, i) in item.options"
                  :key="i"
                  class="ml-1 mt-1 text-1"
                  pill
                  variant="light-dark"
                >
                  {{ it }}
                </b-badge>
              </template>
            </div>
          </template>
          <div
            v-for="(item, index) in attributeList"
            :key="index"
            class="mt-1 d-block"
          >
            <b>{{ item.name_type }}:</b>
            <b-badge
              v-for="(it, i) in item.list"
              :key="i"
              class="ml-1 mt-1 text-1"
              pill
              variant="light-dark"
            >
              {{ it.name }}
            </b-badge>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BTab,
  BBadge,
  BFormInput,
} from 'bootstrap-vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BTab,
    BBadge,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      variations: [
        // {
        //   name: 'Color',
        //   options: ['Red', 'Blue', 'Green', 'Yello'],
        // },
        // {
        //   name: 'Size',
        //   options: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
        // },
        // {
        //   name: 'Metal',
        //   options: ['Leather', 'Cotton', 'Jean'],
        // },
      ],
      attributeList: [],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    repeatVariation() {
      this.variations.push({
        name: null,
        options: [''],
      })
    },
    // removeVariation(index) {
    //   if (index !== 0) {
    //     this.variations.splice(index, 1)
    //   }
    // },
    repeatOption(index) {
      this.variations[index].options.push('')
    },
    removeOption(parentIndex, index) {
      if (index !== 0) {
        this.variations[parentIndex].options.splice(index, 1)
      }
    },
    async handleCreateAttribute() {
      try {
        if (this.variations && this.variations.length > 0) {
          this.variations.map(async x => {
            if (x.options && x.options.length > 0) {
              x.options.map(async y => {
                const params = {
                  template_id: JSON.parse(localStorage.getItem('templateID')).id,
                  name: y,
                  is_active: 1,
                  name_type: x.name,
                }
                const res = await Request.post(
                  this.$http,
                  `${process.env.VUE_APP_API_URL}/platform_second_attributes`,
                  params,
                )
                if (res.status === 200) {
                  if (res.data.status) {
                    this.variations = []
                    this.loadList()
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Login fail',
                        icon: 'AlertOctagonIcon',
                        variant: 'danger',
                        text: String(this.showError(res.data.error, ',')),
                      },
                    })
                  }
                }
              })
            }
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async loadList() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/platform_second_attribute/tree?template_id=${
            JSON.parse(localStorage.getItem('templateID')).id
          }&limit=1000`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.attributeList = res.data.data
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Login fail',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.inline-block {
  display: block;
}
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
.text-1 {
  font-size: 1rem;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>

<template>
  <b-card>
    <b-card-header class="inline-block">
      <b class="h1">Product variations</b>
      <b-link to="/attribute/edit" class="ml-2">
        <feather-icon icon="EditIcon" /> Edit
      </b-link>
      <p>
        Select an attribute and then type the variation and options of the
        products you sell.
      </p>
    </b-card-header>
    <b-card-body>
      <b-tabs content-class="pt-1">
        <attribute-1 />
        <attribute-2 />
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
// import { ValidationProvider } from 'vee-validate'
// eslint-disable-next-line object-curly-newline
import { BCard, BCardBody, BCardHeader, BTabs, BLink } from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Attribute1 from '@/views/pages/attribute/components/add/Attribute1.vue'
import Attribute2 from '@/views/pages/attribute/components/add/Attribute2.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTabs,
    BLink,
    Attribute1,
    Attribute2,
  },
  directives: {
    Ripple,
  },
}
</script>

<style lang="scss" scoped>
.inline-block {
  display: block;
}
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
.text-1 {
  font-size: 1rem;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
